import React, { useState, useRef } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Fade,
} from "@material-ui/core";
import { useNavigate, useParams  } from "react-router-dom";
import { Box, useTheme, useMediaQuery, Button } from "@mui/material";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Header from "components/Header";
import Select, { SelectChangeEvent } from '@mui/material/Select';

import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { paymentApi, generalApi } from "state/api";
import useStyles from "./styles";
import logo from "./digitalpay.png";
import { AuthContext } from "../../context/AuthContext";
import { useEffect } from "react";
import Swal from 'sweetalert2';
import { redirect } from "react-router-dom/dist";
import { capitalize } from "utilities/CommonUtility";

function Checkout() {
  const classes = useStyles();
  const navigate = useNavigate();

  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const textFieldRef = useRef(null);

  let { paymentId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [html, setHtml] = useState("");
  const [providerLogo, setProviderLogo] = useState("./digitalpay.png");
  const [redirectUrl, setRedirectUrl] = useState("");

  const [paidStatus, setPaidStatus] = useState(0); // 0: initial, 1: success, 2: fail
  
  const [payerAccountError, setPayerAccountError] = useState('');  
  const [transactionIdError, setTransactionIdError] = useState('');

  useEffect(() => {

    setIsLoading(true);

    paymentApi.payment().checkout({paymentId})
      .then(res => {   
        console.log('checkout ---- res', res.data);
        if (res.data.success) {
          
          setHtml(res.data.data.html);
          setRedirectUrl(res.data.data.redirectUrl);

          // Swal.fire({
          //   icon: 'success',
          //   title: 'Success!',
          //   text: res.data.data.agentAccount,
          //   showConfirmButton: true,
          // });

        } else {
          
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: res.data.message,
            showConfirmButton: true,
          });   
              
          setPaidStatus(2);

        }

        setIsLoading(false); 

      })
      .catch(err => {
        
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: err.message,
          showConfirmButton: true,
        });
   
        setPaidStatus(2);
          
        setIsLoading(false);

      });

  }, []);

  const handleCancel = async () => {

    Swal.fire({
      icon: 'warning',
      title: 'Warning!',
      text: "You are redirected to your website.",
      showConfirmButton: true,
    });

    window.location = redirectUrl;

  }

  return (
    // <Grid container className={classes.container}>
    <>
      {
        paidStatus === 0 &&
        <div dangerouslySetInnerHTML={{ __html: html }} />
      }
      {
        // paidStatus === 1 &&
        // <div className={classes.logotypeContainer}>
        //   <img src={logo} alt="DigitPay" className={classes.logotypeImage} />
        //   <Typography style={{fontSize: '25px', color: 'white', fontWeight: '800', marginBottom: '30px'}}>
        //     Your payment is received
        //   </Typography>
        //   <Typography style={{fontSize: '16px', color: 'white', fontWeight: '500', marginBottom: '30px'}}>
        //     Please wait while your payment is being processed. Each payment requires manual verification
        //   </Typography>
        //   <Button id="cancel1" variant="contained" onClick={handleCancel}>Close Page</Button>
        // </div>
      }
      {
        paidStatus === 2 &&
        <div className={classes.logotypeContainer}>
          <img src={logo} alt="DigitPay" className={classes.logotypeImage} />
          <Typography style={{fontSize: '25px', color: 'white', fontWeight: '800', marginBottom: '30px'}}>
            Your payment is received
          </Typography>
          <Typography style={{fontSize: '16px', color: 'white', fontWeight: '500', marginBottom: '30px'}}>
            Please try again later.
          </Typography>
          <Button id="cancel2" variant="contained" onClick={handleCancel}>Close Page</Button>
        </div>
      }
    </>
    // </Grid>
  );
}

// export default withRouter(Login);
export default Checkout;
